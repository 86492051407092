import {
  FETCH_COURSE_BY_NAME,
  START_LOADING,
  END_LOADING,
  FETCH_COURSE_BY_ID,
  LIKE_COURSE,
  FETCH_MOST_LIKED_COURSES,
  UPDATE_COURSE,
  UPDATED_COURSES,
  RESET_COURSE,
  RESET_COURSES,
} from "../constants/actionTypes";

export default (state = { courses: [], course: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_COURSE_BY_NAME:
      return { ...state, courses: action.payload };
    case FETCH_COURSE_BY_ID:
      return { ...state, course: action.payload };
    case LIKE_COURSE:
      return {
        ...state,
        course: state.course._id === action.payload._id ? action.payload : state.course,
        courses: state.courses.length > 0 && state.courses.map((course) =>
          course._id === action.payload._id ? action.payload : course
        ),
      };
    case FETCH_MOST_LIKED_COURSES:
      return { ...state, courses: action.payload }
    case UPDATE_COURSE:
      return { ...state, courses: action.payload };
    case UPDATED_COURSES:
      return { ...state, courses: action.payload };
    case RESET_COURSE:
      return { ...state, course: action.payload };
    case RESET_COURSES:
      return { ...state, courses: action.payload };
    default:
      return state;
  }
};
