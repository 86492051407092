import React, { useState, useEffect } from 'react'
import { Button, TextField, Typography, Paper, Grow } from '@material-ui/core';
import logoColor from "../../images/logo-color.png";
import logoWhite from "../../images/logo-white.png";
import ryzeColor from "../../images/ryze-color.png";
import ryzeWhite from "../../images/ryze-white.png";
import { useDispatch } from "react-redux";
import { contactMe } from '../../actions/contact';
import useStyles from "./styles";

const Contact = ({ mode }) => {
    const [contactInfo, setContactInfo] = useState({ contactName: '', contactEmail: '', contactMessage: '' });
    const [contactError, setContactError] = useState({ errors: {} });
    const [formSubbmited, setFormSubbmited] = useState(false)
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleValidation = () => {
        const { contactName, contactEmail, contactMessage } = contactInfo;
        let errors = {};
        let formIsValid = true;

        if (contactName.length < 2) {
            formIsValid = false;
            errors["contactName"] = "Please enter a valid name";
        }
        if (contactEmail.length < 6) {
            formIsValid = false;
            errors["contactEmail"] = "Please enter a valid email";
        }
        if (contactMessage.length < 1) {
            formIsValid = false;
            errors["contactMessage"] = "Please enter a valid message";
        }
        setContactError({ errors: errors });
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            dispatch(contactMe(contactInfo))
            setFormSubbmited(true);
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContactError({ ...contactError, errors: { ...contactError?.errors, [name]: '' } })
        setContactInfo({ ...contactInfo, [name]: value });
    };

    return (
        <Grow in>
            {!formSubbmited ? <div className={classes.outerContactContainer}>
                <Paper raised='true' elevation={4} className={classes.innerContactContainer}>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant='h6' style={{ paddingBottom: '20px' }}>
                            Contact me
                        </Typography>
                        <Typography variant='body1'>
                            This is a one man operation, so I will do my best to respond as quickly as possible.
                        </Typography>
                    </div>
                    <form onSubmit={handleSubmit} autoComplete="off" style={{ textAlign: 'center' }}>
                        <TextField
                            style={{ padding: '10px 0' }}
                            id='contactName'
                            name='contactName'
                            placeholder='Name *'
                            variant='outlined'
                            fullWidth
                            helperText={contactError.errors.contactName ? contactError.errors.contactName : ' '}
                            error={!!contactError.errors.contactName}
                            onChange={handleChange}
                        />
                        <TextField
                            style={{ padding: '10px 0' }}
                            name='contactEmail'
                            placeholder='Email *'
                            variant='outlined'
                            fullWidth
                            helperText={contactError.errors.contactEmail ? contactError.errors.contactEmail : ' '}
                            error={!!contactError.errors.contactEmail}
                            onChange={handleChange}
                        />
                        <TextField
                            style={{ padding: '10px 0' }}
                            name='contactMessage'
                            placeholder='Message *'
                            variant='outlined'
                            fullWidth
                            helperText={contactError.errors.contactMessage ? contactError.errors.contactMessage : ' '}
                            error={!!contactError.errors.contactMessage}
                            multiline
                            minRows={4}
                            onChange={handleChange}
                        />
                        <Button
                            color='primary'
                            variant='contained'
                            style={{ margin: '10px' }}
                            type='submit'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </form>
                </Paper>
            </div> :
                <div className={classes.formSubmittedContainer}>
                    <Paper className={classes.formSubmitted}>
                        <div className={classes.formSent}>
                            <div className={classes.imageContainer}>
                                <img
                                    style={{ marginRight: '10px' }}
                                    src={mode === 'dark' ? logoWhite : logoColor}
                                    alt="Ryze"
                                    height="50"
                                />
                                <img
                                    className={classes.title}
                                    src={mode === 'dark' ? ryzeWhite : ryzeColor}
                                    alt="Ryze"
                                    height="25"
                                />
                            </div>
                        </div>
                        <div className={classes.formCopy}>
                            <Typography variant='h5' gutterBottom >Thanks for contacting me!</Typography>
                            <Typography variant='h5'>If a reply is needed, I will get back to you as soon as possible.</Typography>
                        </div>
                    </Paper>
                </div>
            }
        </Grow>
    );
}

export default Contact