import {
  FETCH_COURSE_BY_NAME,
  FETCH_COURSE_BY_ID,
  START_LOADING,
  END_LOADING,
  LIKE_COURSE,
  FETCH_MOST_LIKED_COURSES,
  ADD_COURSE,
  UPDATE_COURSE,
  UPDATED_COURSES
} from "../constants/actionTypes";
import * as api from "../api";

export const getCourseByName = (name) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchCourseByName(name);
    dispatch({ type: FETCH_COURSE_BY_NAME, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getCourseById = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchCourseById(id);
    dispatch({ type: FETCH_COURSE_BY_ID, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const likeCourse = (id) => async (dispatch) => {
  try {
    const { data } = await api.likeCourse(id);
    dispatch({ type: LIKE_COURSE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getMostLikedCourses = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchMostLikedCourses();
    dispatch({ type: FETCH_MOST_LIKED_COURSES, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const addCourse = (course, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.addCourse(course);
    dispatch({ type: ADD_COURSE, payload: data });

    history.push(`/course/${data._id}`);

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
}

export const updateCourse = (course, history) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.updateCourse(course);
    dispatch({ type: UPDATE_COURSE, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
}

export const getUpdatedCourses = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchUpdatedCourses();
    dispatch({ type: UPDATED_COURSES, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
}