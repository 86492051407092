import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
        margin: "30px 0 0 0",
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: theme.palette.primary.main
    },
    footerSection1: {
        order: '1',
        minHeight: '200px',
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingBottom: '20px'
    },
    footerSection2: {
        order: '0',
        display: 'flex',
        minWidth: '100%',
        padding: '15px',
        justifyContent: 'center'
    },
    footerLink: {
        display: 'block',
        padding: '15px',
        whiteSpace: 'nowrap',
        color: '#fff'
    },
    imageContainer: {
        display: "flex",
        alignItems: "center",
        alignSelf: 'end',
        textDecoration: "none",
        padding: '10px'
    },
    title: {
        display: "block",
        textDecoration: "none",
    },
    footerHeader: {
        color: theme.palette.secondary.light

    }
}));
