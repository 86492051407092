import React, { useState } from "react";
import Grow from "@material-ui/core/Grow";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ChipInput from "material-ui-chip-input";

import Pagination from "../Pagination/Pagination";
import Posts from "../Posts/Posts";
import Form from "../Form/Form";
import { getPostsBySearch } from "../../actions/posts";

import useStyles from "./styles";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const page = query.get("page") || 1;
  const searchQuery = query.get("searchQuery");
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [tags, setTags] = useState([]);

  const searchPost = () => {
    if (searchTerm.trim() || tags) {
      dispatch(getPostsBySearch({ searchTerm, tags: tags.join(",") }));
      history.push(
        `/posts/search?searchQuery=${searchTerm || "none"}&tags=${tags.join(
          ","
        )}`
      );
    } else {
      history.push("/");
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };

  const handleAdd = (tag) => setTags([...tags, tag]);

  const handleDelete = (tagToDelete) =>
    setTags(tags.filter((tag) => tag !== tagToDelete));

  return (
    <Grow in>
      <Grid
        className={classes.gridContainer}
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12} sm={6} md={8}>
          <Posts setCurrentId={setCurrentId} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppBar
            className={classes.appBarSearch}
            position="static"
            color="inherit"
          >
            <TextField
              name="search"
              variant="outlined"
              label="Search Posts"
              onKeyPress={handleKeyPress}
              fullWidth
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            <ChipInput
              style={{ margin: "10px 0" }}
              value={tags}
              onAdd={(tag) => handleAdd(tag)}
              onDelete={(tag) => handleDelete(tag)}
              blurBehavior="add"
              label="Search Tags"
              variant="outlined"
            />
            <Button
              onClick={searchPost}
              className={classes.searchButton}
              color="primary"
              variant="contained"
            >
              Search
            </Button>
          </AppBar>
          <Form currentId={currentId} setCurrentId={setCurrentId} />
          {!searchQuery && !tags.length && (
            <Paper elevation={6} className={classes.pagination}>
              <Pagination page={page} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Grow>
  );
};

export default Home;
