import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  searchInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "end",
  },
  headingTop: {
    paddingTop: '50px',
    fontSize: '35px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
    }
  },
  headingTopDesktop: {
    display: 'inline-block',
    paddingBottom: '50px',
    fontSize: '35px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  headingBottomMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      paddingBottom: '50px',
      fontSize: '25px'
    }
  },
  searchInput: {
    borderRadius: '45px',
    fontSize: '1.8em',
    fontWeight: '300',
    width: '60%',
    backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#fff',
    margin: theme.spacing(2, 0, 12),
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: '45px',
    },
  },

  root: {
    '&:hover $notchedOutline': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  notchedOutline: {
    border: `1px solid ${theme.palette.primary.main}`,
  },

  searchIcon: {
    paddingRight: '10px'
  },
  iconHandler: {
    color: theme.palette.type === 'dark' ? '#FFF' : theme.palette.primary.main
  },
  iconCautionHandler: {
    backgroundColor: theme.palette.error.main,
    color: '#FFF'
  },
  addCourseContainer: {
    margin: '0 0 30px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '77px'
  },
  addCourseCopy: {
    cursor: 'pointer'
  },
  coursesFilterContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: '10px'
  },
  courseSearchContainer: {
    marginBottom: "20px",
    padding: "20px",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: "15px 15px",
      marginBottom: "20px",
    },
  },
  addCourseContainer: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '10px'
  },
  addCourseIcon: {
    color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  mostLikedContainer: {
    paddingLeft: "10px",
  },
  mostLikedIcon: {
    color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  featuredCourse: {
    border: `2px solid ${theme.palette.primary.main}`
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: "120px",
    justifyContent: 'space-between',
    [theme.breakpoints.down("xs")]: {
      display: 'block',
      alignItems: "center",
      padding: "0",
      justifyContent: "space-between",
      height: "auto",
    },
  },
  courseInfoContainer: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down("sm")]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  courseAddressChild1: {
    padding: "10px",
    width: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "300px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  courseAddressChild2: {
    padding: "10px",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  courseAddressChild3: {
    minWidth: "auto",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  courseAddressChild3Admin: {
    display: 'flex',
    width: '300px',
    justifyContent: 'space-between'
  },
  courseLink: {
    cursor: "pointer",
    color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main
  },
  teeBoxTabel: {
    minWidth: "300px",
    maxWidth: "600px",
  },

  scorecardTabel: {
    "& .MuiTableCell-root": {
      border: "2px solid rgba(23, 23, 23)",
      textAlign: "center",
    }
  },
  scorecardHolesCell: {
    fontWeight: "bold !important",
    backgroundColor: theme.palette.primary.main,
    color: "#fff !important",
  },
  scorecardHandicapCell: {
    backgroundColor: "rgba(127, 143, 124) !important",
    color: "#fff !important",
  },
  scorecardParCell: {
    backgroundColor: theme.palette.primary.main,
    color: "rgba(227, 227, 227) !important",
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '300px'
  },
  fakePlayers: {
    backgroundColor: '#fff'
  },
  courseDetailsNav: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '40px'
  },
  courseDetailsInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  courseMapContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  noCoursesFound: {
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));
