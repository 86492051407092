import React from 'react'
import { Typography, Paper, Grow, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import logoColor from "../../images/logo-color.png";
import logoWhite from "../../images/logo-white.png";
import ryzeColor from "../../images/ryze-color.png";
import ryzeWhite from "../../images/ryze-white.png";
import useStyles from "./styles";

const Api = ({ mode }) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  const classes = useStyles();

  return (
    <Grow in>
      <div className={classes.formSubmittedContainer}>
        <Paper className={classes.formSubmitted}>
          <div className={classes.formSent}>
            <div className={classes.imageContainer}>
              <img
                style={{ marginRight: '10px' }}
                src={mode === 'dark' ? logoWhite : logoColor}
                alt="Ryze"
                height="50"
              />
              <img
                className={classes.title}
                src={mode === 'dark' ? ryzeWhite : ryzeColor}
                alt="Ryze"
                height="25"
              />
            </div>
          </div>
          <div className={classes.formCopy}>
            <Typography variant='body1'>Thanks for showing interest in my API!</Typography>
            <Typography variant='body1'>Currently, API services are available through RapidAPI.</Typography>
            <Typography variant='body1' className={classes.formCopyBody}>Check it out here: <a className={classes.courseLink} target="_blank" href='https://rapidapi.com/foshesco-65zCww9c1y0/api/golf-course-api'>Golf Course API</a></Typography>
            <Typography variant='body1' className={classes.formCopyBody}>Feel free to <RouterLink className={classes.courseLink} to='/contact'>contact me</RouterLink> with any questions or comments.</Typography>
          </div>
        </Paper>
      </div>
    </Grow>
  )
}

export default Api