import { makeStyles } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: "0 0 30px 0",
    minHeight: '80px',
    [theme.breakpoints.down("sm")]: {
      minHeight: '70px'
    }
  },
  toolbarContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: '80px',
    // backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.dark : theme.palette.background.light,
    [theme.breakpoints.down("sm")]: {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '70px'
    }
  },
  loginContainer: {
    display: 'flex',
    justifyContent: 'end',
    minWidth: "25%",
    alignItems: "center",
  },
  loginIcon: {
    fontSize: '20px',
    marginRight: '5px',
    color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main,
  },
  userName: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: "25%",
    justifyContent: 'start'
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    alignSelf: 'end',
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageContainerMobile: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    }
  },
  menuButton: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  title: {
    display: "block",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  linksContainer: {
    display: "flex",
    minWidth: "50%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& .indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: '100px',
        width: "100%",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  links: {
    margin: "16px 0",
    cursor: "pointer",
    letterSpacing: '.5px',
    fontWeight: '100',
    '&.MuiTab-wrapper': {
      width: '95%'
    },
    '&:hover': {
      opacity: 1,
    },
    '&.MuiTab-root':{
      minWidth: '120px'
    },
    '&.Mui-focusVisible': {
      '& > span': {
        width: '95%',
      border: `0.5px solid ${theme.palette.primary.main}`
      }
    }
  },
  loginLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  secondToolbar: {
    display: 'flex',
    justifyContent: 'end',
  },
}));
