import axios from "axios";

let API;

if (process.env.NODE_ENV === 'development') {
  API = axios.create({ baseURL: process.env.REACT_APP_SERVER_NAME_DEV });
} else {
  API = axios.create({ baseURL: process.env.REACT_APP_SERVER_NAME });
}

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("profile")).token
      }`;
  }
  return req;
});

export const fetchCourses = (page) => API.get(`/courses?page=${page}`);
export const fetchCourseByName = (name) =>
  API.get(`/courses/search?name=${name}`);
export const fetchCourseById = (id) => API.get(`/courses/${id}`);
export const likeCourse = (id) => API.patch(`/courses/${id}/likeCourse`);
export const fetchMostLikedCourses = () => API.get(`/courses/mostLiked`);
export const addCourse = (course) => API.post('/courses/add', course);
export const updateCourse = (course) => API.post('/courses/update', course);
export const fetchUpdatedCourses = () => API.get('/courses/update/pending')

export const contactMe = (contact) => API.post('/contact/me', contact)

export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPostsBySearch = (searchQuery) =>
  API.get(
    `/posts/search?searchQuery=${searchQuery.searchTerm || "none"}&tags=${searchQuery.tags
    }`
  );
export const createPost = (newPost) => API.post("/posts", newPost);
export const updatePost = (id, updatedPost) =>
  API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const comment = (value, id) =>
  API.post(`/posts/${id}/commentPost`, { value });

export const signin = (formData) => API.post("/user/signin", formData);
export const signup = (formData) => API.post("/user/signup", formData);
