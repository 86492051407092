export const scrollToMe = (element) => {
  setTimeout(() => {
    let block = document.querySelector(element);
    block?.scrollIntoView({ block: 'start' });
    block?.focus({ preventScroll: true });
  }, 100)
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const formatMe = (str) => {
  str = str
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
    .replace(/_/g, " ");
  return str;
};

export const capitalizeMe = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const colorMe = (type, x) => {
  if (type === 'background') {
    let backgroundColor = 'WHITE';
    if (x.toUpperCase().includes('WHITE')) backgroundColor = 'WHITE'
    if (x.toUpperCase().includes('BLACK')) backgroundColor = 'BLACK'
    if (x.toUpperCase().includes('BLUE')) backgroundColor = 'rgba(56, 52, 158)'
    if (x.toUpperCase().includes('GOLD')) backgroundColor = 'rgba(158, 148, 52)'
    if (x.toUpperCase().includes('RED')) backgroundColor = 'rgba(158, 65, 52)'
    return backgroundColor
  }

  if (type === 'font') {
    let fontColor = 'BLACK';
    if (x.toUpperCase().includes('WHITE')) fontColor = 'BLACK'
    if (x.toUpperCase().includes('BLACK')) fontColor = 'WHITE'
    if (x.toUpperCase().includes('BLUE')) fontColor = 'WHITE'
    if (x.toUpperCase().includes('RED')) fontColor = 'WHITE'
    return fontColor
  }
}

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

