import React, { useEffect } from "react";
import { Grow, Typography, Link, Divider, Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getCourseById } from "../../../actions/courses";
import { capitalizeMe, colorMe, scrollToMe } from "../../../helper";
import useStyles from "../styles";
import CourseMap from "./CourseMap";

const CourseDetails = () => {
  const { course } = useSelector((state) => state.courses);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { name = '', phone = '', website = '', city = '', state = '', zip = '', country = '', holes = '', address = '', scorecard = [], teeBoxes = [] } = course;

  useEffect(() => {
    dispatch(getCourseById(id));
    scrollToMe('.MuiContainer-root')
  }, [dispatch, id]);

  let frontNineCard = []
  let backNineCard = []

  let splitScorecard = () => {
    if (scorecard.length > 0) {
      let nineHoles = scorecard.length === 9

      if (!nineHoles) {
        var splitScorecard = Math.ceil(scorecard.length / 2)
        frontNineCard = scorecard.slice(0, splitScorecard)
        backNineCard = scorecard.slice(splitScorecard, scorecard.length)
      } else {
        frontNineCard = scorecard
      }
    }
  }
  splitScorecard();

  const scorecardDetails = () => {
    if (scorecard.length > 0) {
      let scorecardHoles = Object.keys(scorecard[0]).map(x => x === 'Hole' && x)
      let handicap = Object.keys(scorecard[0]).map(x => x === 'Handicap' && x)
      let par = Object.keys(scorecard[0]).map(x => x === 'Par' && x)
      let nineHoles = scorecard.length === 9

      let fakePlayers = Array.from({ length: 3 }, (x, i) => (
        <TableRow key={i} className={classes.fakePlayers}>
          {frontNineCard.map((x, i) => (
            <TableCell key={i}></TableCell>
          ))}
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      ))

      if (!nineHoles) {
        var splitScorecard = Math.ceil(scorecard.length / 2)
        frontNineCard = scorecard.slice(0, splitScorecard)
        backNineCard = scorecard.slice(splitScorecard, scorecard.length)
      } else {
        frontNineCard = scorecard
      }

      let holeOutput = (scorecard) => (
        scorecard.map((x, i) => (
          <TableCell key={i} className={classes.scorecardHolesCell}>{x.Hole.toString().toUpperCase()}</TableCell>
        ))
      )
      let handicapOutput = (scorecard) => (
        scorecard.map((x, i) => (
          <TableCell className={classes.scorecardHandicapCell} key={i}>{x.Handicap}</TableCell>
        ))
      )

      let teeOutput = (arr, tee) => (
        arr.map((teeBoxes, i) => (
          Object.values(teeBoxes.tees).map((key, i) => {
            if (tee.color === key.color) {
              return (
                <TableCell style={{ backgroundColor: colorMe('background', tee.color), color: colorMe('font', tee.color) }} key={i}>{key.yards}
                </TableCell>
              )
            }
          })
        ))
      )

      const totalYards = (scorecard) => (
        scorecard.reduce((acc, curr) => {
          Object.values(curr.tees).map((key, index) => {
            if (!acc[key.color]) {
              acc[key.color] = 0
            }
            acc[key.color] += key.yards
          })
          return acc
        }, {})
      )

      let parOutput = (scorecard) => (
        scorecard.map((x, i) => (
          <TableCell className={classes.scorecardParCell} key={i}>{x.Par}</TableCell>
        ))
      )

      let totalPar = (scorecard) => (
        scorecard.reduce((acc, obj) => {
          return acc + obj.Par
        }, 0)
      )

      return (
        <TableContainer className={classes.scorecardTabel}>
          <Typography variant="h6" style={{ paddingBottom: '30px' }} >Scorecard</Typography>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell className={classes.scorecardHolesCell}>{scorecardHoles}</TableCell>
                {holeOutput(frontNineCard)}
                {!nineHoles ?
                  <>
                    <TableCell className={classes.scorecardHolesCell}>Out</TableCell>
                    <TableCell className={classes.scorecardHolesCell}>Turn</TableCell>
                  </> :
                  <>
                    <TableCell className={classes.scorecardHolesCell}>In</TableCell>
                    <TableCell className={classes.scorecardHolesCell}>Total</TableCell>
                  </>}
              </TableRow>
              {/* Fill in yardages */}
              {Object.values(frontNineCard[0].tees).map((x, i) => (
                <TableRow key={i}>
                  <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>
                    {capitalizeMe(x.color)}
                  </TableCell>
                  {teeOutput(frontNineCard, x)}
                  {!nineHoles ?
                    <>
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>{Object.values(totalYards(frontNineCard))[i]}</TableCell>
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}></TableCell>
                    </>
                    :
                    <>
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>{Object.values(totalYards(frontNineCard))[i]}</TableCell>
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>{Object.values(totalYards(scorecard))[i]}</TableCell>
                    </>
                  }

                </TableRow>
              ))}
              <TableRow>
                <TableCell className={classes.scorecardHandicapCell}>{handicap}</TableCell>
                {handicapOutput(frontNineCard)}
                <TableCell className={classes.scorecardHandicapCell}></TableCell>
                <TableCell className={classes.scorecardHandicapCell}></TableCell>
              </TableRow>
              {/* Add empty rows in scorecard */}
              {fakePlayers}
              <TableRow>
                <TableCell className={classes.scorecardParCell}>{par}</TableCell>
                {parOutput(frontNineCard)}
                <TableCell className={classes.scorecardParCell}>{totalPar(frontNineCard)}</TableCell>
                <TableCell className={classes.scorecardParCell}></TableCell>
              </TableRow>
            </TableBody>
          </Table>

          {!nineHoles &&
            <>
              <Divider style={{ margin: "20px 0" }} />
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.scorecardHolesCell}>{scorecardHoles}</TableCell>
                    {holeOutput(backNineCard)}
                    <TableCell className={classes.scorecardHolesCell}>In</TableCell>
                    <TableCell className={classes.scorecardHolesCell}>Total</TableCell>
                  </TableRow>
                  {/* Fill in yardages */}
                  {Object.values(backNineCard[0].tees).map((x, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>
                        {capitalizeMe(x.color)}
                      </TableCell>
                      {teeOutput(backNineCard, x)}
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>{Object.values(totalYards(backNineCard))[i]}</TableCell>
                      <TableCell style={{ backgroundColor: colorMe('background', x.color), color: colorMe('font', x.color) }}>{Object.values(totalYards(scorecard))[i]}</TableCell>
                    </TableRow>
                  ))
                  }
                  <TableRow>
                    <TableCell className={classes.scorecardHandicapCell}>{handicap}</TableCell>
                    {handicapOutput(backNineCard)}
                    <TableCell className={classes.scorecardHandicapCell}></TableCell>
                    <TableCell className={classes.scorecardHandicapCell}></TableCell>
                  </TableRow>
                  {fakePlayers}
                  <TableRow>
                    <TableCell className={classes.scorecardParCell}>{par}</TableCell>
                    {parOutput(backNineCard)}
                    <TableCell className={classes.scorecardParCell}>{totalPar(backNineCard)}</TableCell>
                    <TableCell className={classes.scorecardParCell}>{totalPar(scorecard)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>}
        </TableContainer>
      );
    }
  };

  return (
    <Grow in>
      <Paper style={{ padding: "20px", borderRadius: "5px" }} elevation={6}>

        <div className={classes.courseDetailsNav}>
          <Typography align="left">
            <Link
              tabIndex='0'
              aria-label={`link to course search`}
              className={classes.courseLink}
              onClick={() => history.push(`/courses`)}>
              Course Search
            </Link>
          </Typography>
          <div>
            <Typography align="right">
              <Link
                tabIndex='0'
                aria-label={`link to edit course '${name}`}
                className={classes.courseLink}
                onClick={() => history.push(`/course/update/${id}`)}>
                Update Course
              </Link>
            </Typography>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={6} lg={6}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <Typography>{address}</Typography>
            <Typography>
              {city && city + ","} {state} {zip}
            </Typography>
            <Typography>{country}</Typography>
            <Typography>{phone}</Typography>
            {website && (
              <Link
                className={classes.courseLink}
                target="_blank"
                href={`${website.includes("http")
                  ? website
                  : "https://" + website
                  }`}
              >
                {website}
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={6} className={classes.courseMapContainer}>
            <CourseMap />
          </Grid>
        </Grid>

        <Divider style={{ margin: "50px 0" }} />
        {scorecard &&
          <>
            {scorecardDetails()}
          </>}
        <Divider style={{ margin: "50px 0" }} />
        {teeBoxes.length > 0 && (
          <>
            <TableContainer>
              <Typography variant="h6" style={{ paddingBottom: '30px' }}>Tee Boxes</Typography>
              <Table className={classes.teeBoxTabel} aria-label="Tee Boxes">
                <TableHead>
                  <TableRow>
                    <TableCell>Tee</TableCell>
                    <TableCell>Slope</TableCell>
                    <TableCell>Handicap</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teeBoxes
                    .sort(
                      (a, b) =>
                        parseFloat(b.handicap) - parseFloat(a.handicap)
                    )
                    .map((teeBox, i) => (
                      <TableRow key={i}>
                        <TableCell>{capitalizeMe(teeBox.tee)}</TableCell>
                        <TableCell>{teeBox.slope}</TableCell>
                        <TableCell>{teeBox.handicap}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider style={{ margin: "50px 0" }} />
          </>
        )}
        {/* <div>
          <Typography variant="h6">
            Posts
          </Typography>
          <Typography variant="h6">Coming soon...</Typography>
          <Divider style={{ margin: "50px 0" }} />
        </div> */}
      </Paper>
    </Grow>
  );
};

export default CourseDetails;
