import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Typography, TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import { commentPost } from "../../actions/posts";

const CommentSection = ({ post }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [comments, setComments] = useState(post?.comments || []);
  const [comment, setComment] = useState("");
  const commentRef = useRef();
  const user = JSON.parse(localStorage.getItem("profile"));

  const handleClick = async () => {
    const finalComment = `${user.result.name}: ${comment}`;

    const newComments = await dispatch(commentPost(finalComment, post._id));

    setComments(newComments);
    setComment("");
  };

  return (
    <div className={classes.commentsOuterContainer}>
      <div className={classes.commentsInnerContainer}>
        <Typography gutterBottom variant="h6">
          Comments
        </Typography>
        {comments.map((comment, index) => (
          <Typography key={index} gutterBottom variant="subtitle1">
            <strong>
              {comment.split(":")[0].split(" ")[0]}{" "}
              {comment.split(":")[0].split(" ")[1][0]}:
            </strong>
            {comment.split(":")[1]}
          </Typography>
        ))}
        <div ref={commentRef} />
      </div>
      {user?.result?.name ? (
        <div style={{ width: "50%" }}>
          <Typography gutterBottom variant="h6">
            Comment
          </Typography>
          <TextField
            fullWidth
            minRows={4}
            variant="outlined"
            multiline
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button
            style={{ marginTop: "10px" }}
            disabled={!comment}
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Comment
          </Button>
        </div>
      ) : (
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography gutterBottom variant="subtitle1">
            <Typography
              component={Link}
              to="/auth"
              className={classes.logout}
              variant="inherit"
              color="primary"
            >
              Sign in
            </Typography>
            to comment
          </Typography>
        </div>
      )}
    </div>
  );
};

export default CommentSection;
