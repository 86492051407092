import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow';
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RESET_COURSE } from '../../../constants/actionTypes'

import { likeCourse } from '../../../actions/courses'
import useStyles from '../styles'

const Course = ({ course, featuredCourse, pending }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("profile"));
    const [likes, setLikes] = useState(course?.likes);

    const userId = user?.result?.sub || user?.result?._id;
    const hasLikedCourse = course?.likes.find((like) => like === userId);

    const handleLike = async () => {
        dispatch(likeCourse(course._id));
        if (hasLikedCourse) {
            setLikes(course.likes.filter((id) => id !== userId));
        } else {
            setLikes([...course.likes, userId]);
        }
    };

    const courseNav = () => {
        dispatch({ type: RESET_COURSE, payload: [] })
        history.push(`/course/${course._id}`)
    }

    const compareUpdate = () => {
        console.log("Comparing update")
    }

    const submitUpdate = () => {
        console.log("Submitting update")
    }

    const rejectUpdate = () => {
        console.log('Rejecting update')
    }

    const Likes = () => {
        if (likes.length > 0) {
            return likes.find((like) => like === userId) ? (
                <>
                    <ThumbUpAltIcon fontSize="small" />
                    &nbsp;
                    {likes.length > 2
                        ? `You and ${likes.length - 1} others`
                        : `${likes.length} like${likes.length > 1 ? "s" : ""}`}
                </>
            ) : (
                <>
                    <ThumbUpAltOutlined fontSize="small" />
                    &nbsp;{course.likes.length} {course.likes.length === 1 ? "Like" : "Likes"}
                </>
            );
        }
        return (
            <>
                <ThumbUpAltOutlined fontSize="small" />
                &nbsp;Like
            </>
        );
    };

    return (
        <Grow in>
            <Paper
                className={`${classes.courseSearchContainer} ${featuredCourse && classes.featuredCourse}`}
                elevation={6}
            >
                {featuredCourse &&
                    <Typography variant='subtitle1' style={{ textAlign: 'right', fontSize: '12px', cursor: 'default' }}>
                        Featured Course
                    </Typography>}
                <Grid className={classes.gridContainer} item>
                    <div className={classes.courseInfoContainer}>
                        <Typography
                            className={classes.courseAddressChild1}
                            variant="h6"
                            color="textPrimary"
                        >
                            <Link
                                tabIndex='0'
                                aria-label={`link to more information on '${course.name}`}
                                className={classes.courseLink}
                                onClick={() => courseNav()}>
                                {course.name}
                            </Link>
                        </Typography>
                        <div className={classes.courseAddressChild2}>
                            <Typography>{course.address}</Typography>
                            <Typography>
                                {course.city && course.city + ","} {course.state}{" "}
                                {course.zip}
                            </Typography>
                            <Typography>{course.country}</Typography>
                            {course.website && (
                                <Link
                                    tabIndex='0'
                                    className={classes.courseLink}
                                    target="_blank"
                                    href={`${course.website.includes("http")
                                        ? course.website
                                        : "https://" + course.website
                                        }`}
                                >
                                    {course.website}
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className={pending ? classes.courseAddressChild3Admin : classes.courseAddressChild3}>
                        {pending && <Button
                            className={classes.iconHandler}
                            size="small"
                            disabled={!user?.result}
                            onClick={pending && compareUpdate}
                        >
                            {pending && 'Compare'}
                        </Button>}
                        <Button
                            className={classes.iconHandler}
                            size="small"
                            disabled={!user?.result}
                            onClick={pending ? submitUpdate : handleLike}
                        >
                            {pending ? 'Update' : <Likes />}
                        </Button>
                        {pending && <Button
                            className={classes.iconCautionHandler}
                            variant='contained'
                            size="small"
                            disabled={!user?.result}
                            onClick={pending && rejectUpdate}
                        >
                            {pending && 'Reject'}
                        </Button>}
                    </div>
                </Grid>
            </Paper>
        </Grow >
    )
}

export default Course