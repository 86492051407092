import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    outerCourseContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    innerCourseContainer: {
        width: "75%",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(8),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2)
        }
    },
    courseCopy: {
        width: '100%',
        textAlign: 'center',
        padding: '20px 0'
    },
    formHeader: {
        marginTop: theme.spacing(2),
        '& .MuiDivider-root': {
            height: 2
        }
    },
    teeBoxHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    teeBoxText: {
        textAlign: 'center',
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(2),
        }
    },
    scorecardRadio: {
        justifyContent: 'center',
        height: '50px',
        flexWrap: 'nowrap',
    },
    iconHandler: {
        color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main,
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main,
        }
    },
    scorecardTableContainer: {
        "& .MuiTableCell-root": {
            border: "1px solid rgba(23, 23, 23)",
            textAlign: "center",
            padding: "8px 8px 8px 8px",
            minWidth: "60px",
            fontWeight: "bold",
        },
        "& .MuiOutlinedInput-input": {
            padding: '8px 8px 8px 8px',
            textAlign: 'center'
        }
    },
    scorecardHolesCell: {
        fontWeight: "bold !important",
        backgroundColor: theme.palette.primary.main,
        color: '#fff !important'
    },
    scorecardHandicapCell: {
        fontWeight: "bold",
    },
    scorecardParCell: {
        fontWeight: "bold",
    },
    submitCourse: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(4),
    },
    floatingLabel: {
        "&.Mui-focused": {
            color: theme.palette.type === 'dark' ? '#FFF' : theme.palette.primary.main
        }
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '300px'
    },
    courseLink: {
        cursor: "pointer",
        color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main
    },
    courseDetailsNav: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '40px'
    },
    formSubmittedContainer: {
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formSubmitted: {
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px'
    },
    formSent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formCopy: {
        paddingTop: '80px'
    },
    imageContainer: {
        display: "flex",
        alignItems: "center",
        alignSelf: 'end',
        textDecoration: "none",
    },
}));
