import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import Link from '@material-ui/core/Link';

import { addCourse, getCourseById, updateCourse } from '../../actions/courses';
import useStyles from "./styles";
import Scorecard from './Scorecard';
import CourseInfo from './CourseInfo';
import TeeBox from './TeeBox';
import logoColor from "../../images/logo-color.png";
import logoWhite from "../../images/logo-white.png";
import ryzeColor from "../../images/ryze-color.png";
import ryzeWhite from "../../images/ryze-white.png";
import { scrollToMe } from '../../helper';

const EditCourse = ({ mode }) => {
    const { id } = useParams();
    const { course, courses, isLoading } = useSelector((state) => state.courses);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [courseName, setCourseName] = useState()
    const [courseInfo, setCourseInfo] = useState();
    const [color, setColor] = useState([])
    const [courseErrors, setCourseErrors] = useState({ errors: {} })
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
        async function injectCourseInfo() {
            let updateCourseUrl = process.env.NODE_ENV !== 'production' ? `${process.env.REACT_APP_SERVER_NAME_DEV}/courses/${id}` : `${process.env.REACT_APP_SERVER_NAME}/courses/${id}`
            
            let output = await fetch(updateCourseUrl)
                .then(response => response.json())
            setCourseInfo(output)
            setCourseName(output.name)
            return
        }
        injectCourseInfo();
    }, []);

    const handleValidation = () => {
        const { name, address, city, zip, state, country, holes, scorecard } = courseInfo;

        let errors = {};
        let formIsValid = true;

        if (name.length < 4) {
            formIsValid = false;
            errors['name'] = 'Please enter a valid name';
        }
        if (address.length < 4) {
            formIsValid = false;
            errors['address'] = 'Please enter a valid address';
        }
        if (city.length < 3) {
            formIsValid = false;
            errors['city'] = 'Please enter a valid city';
        }
        if (state.length < 4) {
            formIsValid = false;
            errors['state'] = 'Please enter a valid state';
        }
        if (zip.length < 5) {
            formIsValid = false;
            errors['zip'] = 'Please enter a valid zip code';
        }
        if (country.length < 4) {
            formIsValid = false;
            errors['country'] = 'Please enter a valid country';
        }

        const checkScorecard = scorecard.some(val => val.Par === 0 || val.Handicap === 0 || val.Par === null || val.Handicap === null)

        if (scorecard.length !== holes && checkScorecard) {
            let scorecardErrorMsg = ''
            if (scorecard.length > 0 && (scorecard[0]?.tees?.teeBox1?.color === scorecard[0]?.tees?.teeBox2?.color)) {
                scorecardErrorMsg = 'Tees cannot have the same name',
                    formIsValid = false;
            } else {
                scorecardErrorMsg = 'Please fill out the entire scorecard'
                formIsValid = false;
            }
            errors['scorecard'] = scorecardErrorMsg;
        }

        setCourseErrors({ errors: errors });

        if (!formIsValid) {
            const element = document.getElementById(Object.keys(errors)[0])
            element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element?.focus({ preventScroll: true });
        } else {
            return formIsValid;
        }
    }

    const handleSubmit = (e) => {
        if (handleValidation()) {
            dispatch(updateCourse(courseInfo, history))
            setIsFormSubmitted(true)
            scrollToMe('#root')
        } else {
            console.log('Error submitting course')
        }
    }

    return (
        <Grow in>
            {!isFormSubmitted ? <div>
                {courseInfo !== undefined ?
                    <Paper elevation={6} style={{ padding: "20px", borderRadius: "5px" }}>
                        <div className={classes.courseDetailsNav}>
                            <Typography align="left">
                                <Link
                                    tabIndex='0'
                                    aria-label={`link back to '${courseName}`}
                                    className={classes.courseLink}
                                    onClick={() => history.push(`/course/${id}`)}>
                                    Back to Course
                                </Link>
                            </Typography>
                        </div>
                        <Grid container spacing={2}>
                            <div className={classes.courseCopy}>
                                <Typography variant="h4" gutterBottom>
                                    Updating
                                </Typography>
                                <Typography variant="h4" gutterBottom>
                                    {courseName}
                                </Typography>
                            </div>
                            <CourseInfo
                                courseInfo={courseInfo}
                                setCourseInfo={setCourseInfo}
                                courseErrors={courseErrors}
                                setCourseErrors={setCourseErrors}
                            />
                            <TeeBox
                                courseInfo={courseInfo}
                                setCourseInfo={setCourseInfo}
                            />
                            <Scorecard
                                courseInfo={courseInfo}
                                setCourseInfo={setCourseInfo}
                                color={color}
                                setColor={setColor}
                                courseErrors={courseErrors}
                                setCourseErrors={setCourseErrors}
                            />
                            <Grid item xs={12} className={classes.submitCourse}>
                                <Button variant='contained' color='primary' onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper> :
                    <div className={classes.spinnerContainer}>
                        <CircularProgress />
                    </div>
                }
            </div> :
                <div className={classes.formSubmittedContainer}>
                    <Paper className={classes.formSubmitted}>
                        <div className={classes.formSent}>
                            <div className={classes.imageContainer}>
                                <img
                                    style={{ marginRight: '10px' }}
                                    src={mode === 'dark' ? logoWhite : logoColor}
                                    alt="Ryze"
                                    height="50"
                                />
                                <img
                                    className={classes.title}
                                    src={mode === 'dark' ? ryzeWhite : ryzeColor}
                                    alt="Ryze"
                                    height="25"
                                />
                            </div>
                        </div>
                        <div className={classes.formCopy}>
                            <Typography variant='h5' gutterBottom >Thanks for updating {courseName}!</Typography>
                            <Typography variant='h5'>I will review the update and get it published as soon as possible.</Typography>
                        </div>
                    </Paper>
                </div>
            }
        </Grow>
    )
}

export default EditCourse