import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  media: {
    borderRadius: "5px",
    objectFit: "cover",
    width: "100%",
    maxHeight: "500px",
  },
  card: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
    },
  },
  postMessage: {
    padding: "20px 0",
    [theme.breakpoints.up("md")]: {
      height: '200px'
    },
  },
  postInfoSection: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    marginBottom: '20px'
  },
  imageSection: {
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  titleSubheading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recommendedPosts: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loadingPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
    height: "39vh",
  },
  commentsOuterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  commentsInnerContainer: {
    height: "200px",
    overflowY: "auto",
    marginRight: "20px",
    width: '50%'
  },
  logout: {
    textDecoration: 'none',
    padding: '8px 8px',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
