import { Typography } from '@material-ui/core'
import React from 'react'

const NotFound = () => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '400px' }}>
            <Typography style={{ textAlign: 'center' }}>Uh, put me down for a par...</Typography>
        </div>
    )
}

export default NotFound