import { useEffect, useState } from 'react'
import { getCourseById } from "../../../actions/courses";

import { MapContainer, TileLayer } from 'react-leaflet'
import { openStreetMap } from '../../../constants/index'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CourseMap = () => {
    const { course } = useSelector((state) => state.courses);
    const [hasCoordinates, setHasCoordinates] = useState(false);
    const [coordinates, setCoordinates] = useState();
    // const [findCoordinates, setFindCoordinates] = useState();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        let ignore = false;

        // Fetch current course by id
        dispatch(getCourseById(id))

        // Fetch course by address if coordinates are not available in db
        if (course.name && (course.coordinates === undefined || course.coordinates === '')) {
            startFetching();
        }

        // Set coordinates if coordinates are available in db
        if (course.name && course.coordinates !== undefined && course.coordinates !== '') {
            let coordinates = course?.coordinates?.replace(/[()]/g, '').split(',')
            let latAndLong = course.coordinates && { lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1]) }

            setCoordinates({ lat: latAndLong.lat, lng: latAndLong.lng })
            setHasCoordinates(true)
        }

        async function startFetching() {
            const result = await fetch(`https://nominatim.openstreetmap.org/search?street=${course.address || course.name}&city=${course.city}&state=${course.state}&format=json&limit=1`)
                .then(response => response.json());
            if (!ignore) {
                setCoordinates({ lat: result[0].lat, lng: result[0].lon })
                setHasCoordinates(true)
            }
            return () => {
                ignore = true;
            }
        }

    }, [dispatch, id, course.name]);

    return (
        hasCoordinates &&
        <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={15}
            scrollWheelZoom={true}
            style={{ height: '500px', width: '550px' }}>
            <TileLayer
                attribution={openStreetMap.ATTRIBUTION}
                url={openStreetMap.URL}
            />
        </MapContainer>
    )
}

export default CourseMap