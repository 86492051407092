import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useStyles from "./styles";

const TeeBox = (props) => {
    const classes = useStyles();
    const { courseInfo, setCourseInfo } = props;

    let newArr = [...courseInfo.teeBoxes]
    const handleTeeBoxes = (e, teeIndex) => {
        const { name, value } = e.target;

        if (name === 'tee') {
            newArr[teeIndex] = { ...newArr[teeIndex], 'tee': value }
        }
        if (name === 'slope') {
            newArr[teeIndex] = { ...newArr[teeIndex], 'slope': value }
        }
        if (name === 'handicap') {
            newArr[teeIndex] = { ...newArr[teeIndex], 'handicap': value }
        }
    }

    const handleBlur = () => {
        setCourseInfo({ ...courseInfo, teeBoxes: newArr })
    }

    return (
        <>
            <Grid className={classes.formHeader} item xs={12}>
                <Typography variant='h6'>
                    Tee Boxes
                </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.teeBoxHeader}>
                <Typography className={classes.teeBoxText}>
                    Tee Box 1:
                </Typography>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 10 }}
                    name='tee'
                    label="Name"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 0)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 3 }}
                    name="slope"
                    label="Slope"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 0)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 5 }}
                    name="handicap"
                    label="Handicap"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 0)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={12} sm={3} className={classes.teeBoxHeader}>
                <Typography className={classes.teeBoxText}>
                    Tee Box 2:
                </Typography>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 10 }}
                    name='tee'
                    label="Name"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 1)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 3 }}
                    name="slope"
                    label="Slope"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 1)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 5 }}
                    name="handicap"
                    label="Handicap"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 1)}
                    onBlur={handleBlur}
                />
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12} sm={3} className={classes.teeBoxHeader}>
                <Typography className={classes.teeBoxText}>
                    Tee Box 3:
                </Typography>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 10 }}
                    name='tee'
                    label="Name"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 2)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 3 }}
                    name="slope"
                    label="Slope"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 2)}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <TextField
                    inputProps={{ maxLength: 5 }}
                    name="handicap"
                    label="Handicap"
                    variant="outlined"
                    fullWidth
                    autoComplete='nope'
                    onChange={(e) => handleTeeBoxes(e, 2)}
                    onBlur={handleBlur}
                />
            </Grid>
        </>
    )
}

export default TeeBox