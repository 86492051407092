import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getUpdatedCourses } from "../../actions/courses";
import Course from "../Courses/Course/Course";
import Typography from '@material-ui/core/Typography';
import { isEmpty } from "../../helper";

const Dashboard = () => {
    const user = JSON.parse(localStorage.getItem("profile"));
    const dispatch = useDispatch();
    const history = useHistory();
    const { course, courses, isLoading } = useSelector((state) => state.courses);

    useEffect(() => {
        if (user.result.admin !== true) {
            history.push('/courses')
        } else {
            dispatch(getUpdatedCourses())
        }
    }, [])

    return (
        <div>
            <Typography variant='h3' align='center'>Dashboard</Typography>
            <Typography variant='h6' gutterBottom>Pending Course Updates:</Typography>
            {courses.length > 0 ? courses.map((course) => (
                <Course key={course._id} course={course} featuredCourse={false} pending={true} />
            )) : !isEmpty(course) && <Course course={course} featuredCourse={false} pending={true} />}
        </div>
    )
}

export default Dashboard