import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { addCourse } from '../../actions/courses';
import useStyles from "./styles";
import Scorecard from './Scorecard';
import CourseInfo from './CourseInfo';
import TeeBox from './TeeBox';


const AddCourse = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [courseInfo, setCourseInfo] = useState({
        name: "",
        phone: "",
        website: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        holes: "",
        holes: '9',
        teeBoxes: [],
        scorecard: []
    });
    const [color, setColor] = useState([])
    const [courseErrors, setCourseErrors] = useState({ errors: {} })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleValidation = () => {
        const { name, address, city, zip, state, country, holes, scorecard } = courseInfo;

        let errors = {};
        let formIsValid = true;

        if (name.length < 4) {
            formIsValid = false;
            errors['name'] = 'Please enter a valid name';
        }
        if (address.length < 4) {
            formIsValid = false;
            errors['address'] = 'Please enter a valid address';
        }
        if (city.length < 3) {
            formIsValid = false;
            errors['city'] = 'Please enter a valid city';
        }
        if (state.length < 4) {
            formIsValid = false;
            errors['state'] = 'Please enter a valid state';
        }
        if (zip.length < 5) {
            formIsValid = false;
            errors['zip'] = 'Please enter a valid zip code';
        }
        if (country.length < 4) {
            formIsValid = false;
            errors['country'] = 'Please enter a valid country';
        }

        const checkScorecard = scorecard.some((val, index) => val.Par === 0 && val.Handicap === 0)

        if (scorecard.length !== Number(holes) && checkScorecard) {
            let scorecardErrorMsg = ''
            if (scorecard.length > 0 && (scorecard[0]?.tees?.teeBox1?.color === scorecard[0]?.tees?.teeBox2?.color)) {
                scorecardErrorMsg = 'Tees cannot have the same name';
                formIsValid = false;
            } else {
                scorecardErrorMsg = 'Please fill out the entire scorecard'
                formIsValid = false;
            }
            errors['scorecard'] = scorecardErrorMsg;
        }

        setCourseErrors({ errors: errors });

        if (!formIsValid) {
            const element = document.getElementById(Object.keys(errors)[0])
            element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element?.focus({ preventScroll: true });
        } else {
            return formIsValid;
        }
    }

    const handleSubmit = (e) => {
        if (handleValidation()) {
            dispatch(addCourse(courseInfo, history))
        } else {
            console.log('Error submitting course')
        }
    }

    return (
        <Grow in>
            <div className={classes.outerCourseContainer}>
                <Paper elevation={4} className={classes.innerCourseContainer}>
                    <Grid container spacing={2}>
                        <div className={classes.courseCopy}>
                            <Typography variant="h4" gutterBottom>
                                Add Your Course
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Add your home course or a course you recently played.
                            </Typography>
                        </div>
                        <CourseInfo
                            courseInfo={courseInfo}
                            setCourseInfo={setCourseInfo}
                            courseErrors={courseErrors}
                            setCourseErrors={setCourseErrors}
                        />
                        <TeeBox
                            courseInfo={courseInfo}
                            setCourseInfo={setCourseInfo}
                        />
                        <Scorecard
                            courseInfo={courseInfo}
                            setCourseInfo={setCourseInfo}
                            color={color}
                            setColor={setColor}
                            courseErrors={courseErrors}
                            setCourseErrors={setCourseErrors}
                        />
                        <Grid item xs={12} className={classes.submitCourse}>
                            <Button variant='contained' color='primary' onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Grow>
    )
}

export default AddCourse