import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    outerContactContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    innerContactContainer: {
        width: "75%",
        minHeight: '750px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '50px',

        [theme.breakpoints.down("sm")]: {
            width: '100%',
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2)
        }
    },
    formSubmittedContainer: {
        height: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formSubmitted: {
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px'
    },
    formSent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formCopy: {
        paddingTop: '80px',
    },
    formCopyBody: {
        padding: '20px 0 0 0'
    },
    imageContainer: {
        display: "flex",
        alignItems: "center",
        alignSelf: 'end',
        textDecoration: "none",
    },
    title: {
        display: "block",
        textDecoration: "none",
    },
    courseLink: {
        cursor: "pointer",
        color: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.primary.main
    },
}))