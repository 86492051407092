import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { isEmpty } from '../../helper'
import countries from '../../constants/countries.json'
import states from '../../constants/states.json'
import useStyles from "./styles";

const CourseInfo = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { courseInfo, setCourseInfo, courseErrors, setCourseErrors } = props;

    const handleBlur = (e) => {
        const { name, value } = e.target;
        setCourseInfo({
            ...courseInfo,
            [name]: value,
        });
        setCourseErrors({
            ...courseErrors,
            errors: { ...courseErrors?.errors, [name]: '' }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourseInfo({
            ...courseInfo,
            [name]: value,
        });
        setCourseErrors({
            ...courseErrors,
            errors: { ...courseErrors?.errors, [name]: '' }
        })
    }

    return (
        <>
            <Grid className={classes.formHeader} item xs={12}>
                <Typography variant="h6">
                    Course Information
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    inputProps={{ maxLength: 30 }}
                    required
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="outlined"
                    autoComplete='nope'
                    value={courseInfo.name}
                    helperText={courseErrors.errors.name ? courseErrors.errors.name : ' '}
                    error={!!courseErrors.errors.name}
                    onBlur={(e) => handleBlur(e)}
                    onChange={(e) => handleChange(e)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    inputProps={{ maxLength: 30 }}
                    required
                    id="address"
                    name="address"
                    label="Address line 1"
                    fullWidth
                    variant="outlined"
                    autoComplete='nope'
                    value={courseInfo.address}
                    helperText={courseErrors.errors.address ? courseErrors.errors.address : ' '}
                    error={!!courseErrors.errors.address}
                    onBlur={(e) => handleBlur(e)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    inputProps={{ maxLength: 20 }}
                    required
                    id="city"
                    name="city"
                    label="City"
                    fullWidth
                    variant="outlined"
                    autoComplete='nope'
                    value={courseInfo.city}
                    helperText={courseErrors.errors.city ? courseErrors.errors.city : ' '}
                    error={!!courseErrors.errors.city}
                    onBlur={(e) => handleBlur(e)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl
                    required
                    variant="outlined"
                    autoComplete='nope'
                    fullWidth
                    error={!!courseErrors.errors.state}
                    className={classes.formControl}>
                    <InputLabel htmlFor="outlined-state-native-simple">State</InputLabel>
                    <Select
                        native
                        value={courseInfo.state}
                        onBlur={(e) => handleBlur(e)}
                        label="State"
                        inputProps={{
                            name: 'state',
                            id: 'state'
                        }}
                    >
                        {states.length > 0 && states.map(({ name }) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </Select>
                    {courseErrors.errors.state ?
                        <FormHelperText>
                            {courseErrors.errors.state}
                        </FormHelperText> :
                        <FormHelperText>
                            &nbsp;
                        </FormHelperText>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    inputProps={{ maxLength: 5 }}
                    required
                    id="zip"
                    name="zip"
                    label="Zip code"
                    fullWidth
                    variant="outlined"
                    autoComplete='nope'
                    value={courseInfo.zip}
                    helperText={courseErrors.errors.zip ? courseErrors.errors.zip : ' '}
                    error={!!courseErrors.errors.zip}
                    onBlur={(e) => handleBlur(e)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl
                    variant="outlined"
                    autoComplete='nope'
                    fullWidth
                    required
                    className={classes.formControl}
                    error={!!courseErrors.errors.country}
                >
                    <InputLabel htmlFor="outlined-country-native-simple">Country</InputLabel>
                    <Select
                        native
                        value={courseInfo.country}
                        onBlur={(e) => handleBlur(e)}
                        label="Country"
                        inputProps={{
                            id: 'country',
                            name: 'country',
                        }}
                    >
                        {countries.length > 0 && countries.map(({ name, id }) => (
                            <option key={id} value={name}>
                                {name}
                            </option>
                        ))}
                    </Select>
                    {courseErrors.errors.country ?
                        <FormHelperText>
                            {courseErrors.errors.country}
                        </FormHelperText> :
                        <FormHelperText>
                            &nbsp;
                        </FormHelperText>
                    }
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    inputProps={{ maxLength: 10, type: 'tel' }}
                    id="phone"
                    name="phone"
                    label="Phone number"
                    fullWidth
                    variant="outlined"
                    autoComplete='nope'
                    value={courseInfo.phone}
                    onBlur={(e) => handleBlur(e)}
                    helperText={' '}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    inputProps={{ maxLength: 20 }}
                    id="website"
                    name="website"
                    label="Website"
                    fullWidth
                    variant="outlined"
                    autoComplete='nope'
                    value={courseInfo.website}
                    onBlur={(e) => handleBlur(e)}
                    helperText={' '}
                />
            </Grid>
        </>
    )
}

export default CourseInfo