import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Link from '@material-ui/core/Link'
import decode from "jwt-decode";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import logoColor from "../../images/logo-color.png";
import logoWhite from "../../images/logo-white.png";
import ryzeColor from "../../images/ryze-color.png";
import ryzeWhite from "../../images/ryze-white.png";
import useStyles from "./styles";
import { LOGOUT } from "../../constants/actionTypes";
import { AppBar, Icon } from "@material-ui/core";
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const Navbar = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const anchorRef = React.useRef(null);
  const { mode, setMode } = props;

  const login = () => {
    history.push("/auth");
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
    setUser(null);
    handleProfileMenuClose();
    history.push("/");
  };

  const admin = () => {
    handleProfileMenuClose();
    history.push('/dashboard');
  }

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout();
      }
    }
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [linkAnchorEl, setLinkAnchorEl] = useState(null);

  const isLinkMenuOpen = Boolean(profileAnchorEl);
  const isProfileMenuOpen = Boolean(linkAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
    handleLinkMenuClose();
  };

  const handleLinkMenuOpen = (event) => {
    setLinkAnchorEl(event.currentTarget);
  };

  const handleLinkMenuClose = () => {
    setLinkAnchorEl(null);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const menuId = "primary-search-account-menu";
  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      id={menuId}
      keepMounted
      open={isLinkMenuOpen}
      onClose={handleProfileMenuClose}
    >
      {user?.result?.admin === true && <MenuItem onClick={admin}>Admin</MenuItem>}
      <MenuItem variant="contained" color="inherit" onClick={logout}>Sign Out</MenuItem>
    </Menu>
  );

  const linkId = "primary-search-link-menu";
  const renderLinkMenu = (
    <Menu
      anchorEl={linkAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      getContentAnchorEl={null}
      id={menuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleLinkMenuClose}
      color='textPrimary'
    >
      <MenuItem
        onClick={handleLinkMenuClose}
        component={RouterLink}
        to="/courses"
      >
        Courses
      </MenuItem>
      <MenuItem
        onClick={handleLinkMenuClose}
        component={RouterLink}
        to="/courses/add"
      >
        Add Course
      </MenuItem>
      <MenuItem
        onClick={handleLinkMenuClose}
        component={RouterLink}
        to="/posts"
      >
        Posts
      </MenuItem>
      <MenuItem
        onClick={handleLinkMenuClose}
        component={RouterLink}
        to="/contact"
      >
        Contact
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position='static' elevation={0} color='inherit'>
        <Toolbar className={classes.toolbarContainer}>
          <div className={classes.brandContainer}>
            <IconButton
              name={linkId}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleLinkMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <RouterLink to="/" className={classes.imageContainer}>
              <img
                style={{ marginRight: '10px' }}
                src={mode === 'dark' ? logoWhite : logoColor}
                alt="Ryze"
                height="50"
              />
              <img
                className={classes.title}
                src={mode === 'dark' ? ryzeWhite : ryzeColor}
                alt="Ryze"
                height="25"
              />
            </RouterLink>
          </div>
          <RouterLink to="/" className={classes.imageContainerMobile}>
            <img
              src={mode === 'dark' ? ryzeWhite : ryzeColor}
              alt="Ryze"
              height="25"
            />
          </RouterLink>
          <div className={classes.linksContainer}>
            <Tabs value={value} onChange={handleChange} aria-label="nav bar links" centered
              classes={{
                flexContainer: "flexContainer",
                indicator: "indicator",
              }}
              TabIndicatorProps={{ children: <span /> }}
            >
              <Tab className={classes.links} disableRipple component={RouterLink} label="Courses" {...a11yProps(0)} to='/courses' />
              <Tab className={classes.links} disableRipple component={RouterLink} label="Add Course" {...a11yProps(1)} to='/courses/add' />
              <Tab className={classes.links} disableRipple component={RouterLink} label="Posts" {...a11yProps(2)} to='/posts' />
              <Tab className={classes.links} disableRipple component={RouterLink} label="Contact" {...a11yProps(3)} to='/contact' />
            </Tabs>
          </div>
          {user ? (
            <div className={classes.loginContainer}>
              <Typography className={classes.userName} variant="h6">
                {user.result.name.split('')}
              </Typography>
              <Avatar
                ref={anchorRef}
                className={classes.purple}
                alt={user.result.name}
                src={user.result.imageUrl}
                onClick={handleProfileMenuOpen}
                aria-controls={menuId}
                aria-haspopup="true"
                aria-label="account of current user"
                style={{ cursor: "pointer" }}
              >
                {user.result.name.charAt(0)}
              </Avatar>
            </div>
          ) : (
            <div className={classes.loginContainer}>
              <Link tabIndex={0} onClick={login} color='inherit' className={classes.loginLink}>
                <AccountCircleIcon className={classes.loginIcon} style={{ fontSize: '20px', marginRight: '5px' }} />
                <Typography>Sign In</Typography>
              </Link>
            </div>
          )}
          {renderProfileMenu}
          {renderLinkMenu}
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.secondToolbar}>
        {mode === 'dark' ?
          <IconButton onClick={() => setMode(mode === "dark" ? "light" : "dark")}>
            <Brightness7Icon />
          </IconButton> :
          <IconButton onClick={() => setMode(mode === "dark" ? "light" : "dark")}>
            <Brightness4Icon />
          </IconButton>
        }
      </Toolbar>
    </>
  );
};

export default Navbar;
