import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  authContainer: {
    minHeight: '550px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleLogin: {
    marginBottom: theme.spacing(2),
  },
  input: {
    WebkitBoxShadow: theme.palette.type === 'dark' ? '0 0 0 1000px #424242 inset !important' : '0 0 0 1000px #fff inset !important'
  }
}));
