import {
    CONTACT_ME,
    START_LOADING,
    END_LOADING
} from "../constants/actionTypes";
import * as api from "../api";

export const contactMe = (contact) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });

        const { data } = await api.contactMe(contact);
        dispatch({ type: CONTACT_ME, payload: data });

        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }
}