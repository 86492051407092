// Wing Park Golf Course
export const FEATURED_COURSE_ID = "6452b919f7c98ef137302155";

export const courseDetails = {
    USA: { lat: 39.8283, lng: -98.5795 },
    RESET: { lat: 37.0902, lng: -95.7129, name: 'Reset' },
    AUGUSTA: { lat: 33.499998, lng: -82.01999992, name: 'Augusta National' },
    PINEHURST2: { lat: 35.189496348638, lng: -79.467680568914, name: 'Pinehurst No. 2' },
    BETHPAGE: { lat: 40.7387170451, lng: -73.4531331875, name: 'Bethpage Black' },
    SOUTHERNHILLS: { lat: 36.0745, lng: -95.9548, name: 'Southern Hills CC' },
    THECOUNTRYCLUB: { lat: 42.3148, lng: -71.1478, name: 'The Country Club' },
    STANDREWSOLD: { lat: 56.3438, lng: -2.8031, name: 'St. Andrews (Old Course)' }
}

export const openStreetMap = {
    ATTRIBUTION: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}