import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  ul: {
    justifyContent: "space-around",
  },
  hidePagination: {
    display: 'none'
  }

}));
