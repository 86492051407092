import React from 'react'
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import logoWhite from "../../images/logo-white.png";
import ryzeWhite from "../../images/ryze-white.png";
import { AppBar, Paper, Toolbar, Typography, Link } from '@material-ui/core'
import useStyles from './styles'

const Footer = () => {
    const classes = useStyles();

    return (
        <Toolbar className={classes.footerContainer} square='true' elevation={0} position='static'>
            <div className={classes.footerSection1}>
                <Typography variant='h6' className={classes.footerHeader}>Site Map</Typography>
                <RouterLink color='inherit' className={classes.footerLink} to='/courses'>Courses</RouterLink>
                <RouterLink color='inherit' className={classes.footerLink} to='/courses/add'>Add Course</RouterLink>
                <RouterLink color='inherit' className={classes.footerLink} to='/posts'>Posts</RouterLink>
            </div>
            <div className={classes.footerSection2}>
                <RouterLink to="/courses" className={classes.imageContainer}>
                    <img
                        style={{ marginRight: '10px' }}
                        src={logoWhite}
                        alt="Ryze"
                        height="50"
                    />
                    <img
                        className={classes.title}
                        src={ryzeWhite}
                        alt="Ryze"
                        height="25"
                    />
                </RouterLink>
            </div>
            <div className={classes.footerSection1}>
                <Typography variant='h6' className={classes.footerHeader}>More</Typography>
                <RouterLink color='inherit' className={classes.footerLink} to='/contact'>Contact</RouterLink>
                <RouterLink color='inherit' className={classes.footerLink} to='/api'>API</RouterLink>
            </div>
        </Toolbar >
    )
}

export default Footer