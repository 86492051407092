import React, { useState, useEffect, useMemo } from "react";
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter, Switch as SwitchRouter, Route, Redirect } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import PostDetails from "./components/PostDetails/PostDetails";
import Navbar from "./components/Navbar/Navbar";
import Profile from "./components/Profile/Profile";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import Courses from "./components/Courses/Courses";
import AddCourse from "./components/AddCourse/AddCourse";
import CourseDetails from "./components/Courses/Course/CourseDetails";
import EditCourse from "./components/EditCourse/EditCourse";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import NotFound from "./components/NotFound/NotFound";
import Api from "./components/API/Api";
import Dashboard from "./components/Dashboard/Dashboard";

const App = () => {
  const [deviceType, setDeviceType] = useState("");
  const [mode, setMode] = useState("light");

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
      setDeviceType("mobile");
    } else {
      setDeviceType("desktop");
    }
  }, []);

  const theme = useMemo(() =>
    createTheme({
      palette: {
        type: mode,
        primary: {
          light: '#B8DBD9',
          main: '#04724D',
          // dark: '#000000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#FFF',
          main: '#586F7C',
          dark: '#ba000d',
          contrastText: '#000',
        },
        error: {
          main: '#ff1f1f',
        }
      },
    }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID}>
          <Navbar setMode={setMode} mode={mode} />
          <Container maxWidth="lg" className="content">
            <SwitchRouter>
              <Route path="/" exact component={() => <Redirect to="/courses" />} />
              <Route path="/posts" exact component={Home} />
              <Route path="/posts/search" exact component={Home} />
              <Route path="/posts/:id" component={() => <PostDetails deviceType={deviceType} />} />
              <Route path="/courses" exact component={Courses} />
              <Route path="/courses/add" exact component={AddCourse} />
              <Route path="/course/update/:id" exact component={() => <EditCourse mode={mode} />} />
              <Route path="/course/:id" exact component={() => <CourseDetails deviceType={deviceType} />} />
              <Route path="/api" exact component={() => <Api mode={mode} />} />
              <Route path="/contact" exact component={() => <Contact mode={mode} />} />
              <Route path="/auth" exact component={() => <Auth deviceType={deviceType} />} />
              <Route path="/profile" exact component={() => <Profile />} />
              <Route path="/dashboard" exact component={() => <Dashboard />} />
              <Route component={NotFound} />
            </SwitchRouter>
          </Container>
          <Footer />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
