import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import GolfCourseIcon from '@material-ui/icons/GolfCourse';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from "@material-ui/core/IconButton";
import ReactGA from 'react-ga4';

import { getCourseByName, getCourseById, getMostLikedCourses } from "../../actions/courses";
import Course from "./Course/Course";
import { isEmpty } from "../../helper";
import { RESET_COURSES } from "../../constants/actionTypes";
import { FEATURED_COURSE_ID } from "../../constants/index";
import useStyles from "./styles";

const Courses = () => {
  const dispatch = useDispatch();
  const [courseName, setCourseName] = useState("");
  const [mostLikedClick, setMostLikedClick] = useState(false);
  const { course, courses, isLoading } = useSelector((state) => state.courses);
  const history = useHistory();
  const classes = useStyles();

  const searchCourse = (e) => {
    let courseName = e.target.value;
    ReactGA.event({
      category: 'Course',
      action: 'Course search input',
      label: courseName
    });
    if (courseName.length === 0) {
      dispatch({ type: RESET_COURSES, payload: [] });
      setCourseName(courseName);
      return;
    } else {
      setCourseName(courseName);
      dispatch(getCourseByName(courseName));
    }
  };

  const mostLiked = (e) => {
    if (!mostLikedClick) {
      setMostLikedClick(!mostLikedClick)
      dispatch(getMostLikedCourses())
    } else {
      setMostLikedClick(!mostLikedClick)
      dispatch(getCourseByName(''))
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getCourseById(FEATURED_COURSE_ID))
    dispatch({ type: RESET_COURSES, payload: [] });
  }, [dispatch]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchCourse();
    }
  };

  return (
    <>
      <Grow in>
        <div className={classes.searchInputContainer}>
          <div align='center' className={classes.headingTop}>
            Search the RYZE database <Typography className={classes.headingTopDesktop}>with over 20,000 courses</Typography>
          </div>
          <Typography align='center' className={classes.headingBottomMobile}>
            with over 20,000 courses
          </Typography>
          <TextField
            InputProps={{
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
                input: classes.input
              },
              startAdornment: (
                <InputAdornment position="start" className={classes.searchIcon}>
                  <GolfCourseIcon className={classes.iconHandler} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            color="primary"
            className={classes.searchInput}
            placeholder="Search Courses"
            onKeyDown={handleKeyPress}
            value={courseName}
            onChange={(e) => {
              searchCourse(e);
            }}
          />
        </div>
      </Grow>
      {isLoading && courses.length < 1 ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.coursesFilterContainer}>
            <FormGroup row >
              <div className={classes.addCourseContainer} onClick={() => history.push('/courses/add')}>
                <IconButton className={classes.addCourseIcon}>
                  <AddCircleIcon className={classes.iconHandler} />
                </IconButton>
                <Typography align='center' className={classes.addCourseCopy}>
                  Add Course
                </Typography>
              </div>
              <FormControlLabel
                className={classes.mostLikedContainer}
                control={
                  <Switch
                    className={classes.mostLikedIcon}
                    checked={mostLikedClick}
                    onChange={mostLiked}
                    name="checked"
                    color="primary"
                  />
                }
                label="Most Liked"
              />
            </FormGroup>
          </div>
          {/* Courses Returned By Search */}
          {courses.length > 0 && courses.map((course) => (
            <Course key={course._id} course={course} featuredCourse={false} />
          ))}
          {/* Featured Course or No Courses Found */}
          {courses.length < 0 || courses.message === undefined ?
            !isEmpty(course) && <Course course={course} featuredCourse={true} /> :
            <div className={classes.noCoursesFound}>
              <Typography>No Courses Found</Typography>
            </div>
          }
        </>
      )
      }
    </>
  );
};

export default Courses;
