import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import useStyles from "./styles";
import { Button, FormHelperText } from '@material-ui/core'

const Scorecard = (props) => {
    const classes = useStyles();
    const { courseInfo = {}, setCourseInfo, color, setColor, courseErrors, setCourseErrors } = props;
    const [numOfRows, setNumOfRows] = useState([])

    useEffect(() => {
        let totalRows = [];
        let rows = Object.keys(courseInfo?.scorecard[0]?.tees || [1])
        rows.map((x, i) => {
            totalRows.push(i + 1)
        })
        setNumOfRows(totalRows)
    }, [])

    const nineOrEighteen = (event) => {
        let { value } = event.target;
        let scorecardData = [...courseInfo.scorecard]
        let holeOutline = {
            'Handicap': null,
            'Hole': null,
            'Par': null,
            'tees': {
                ...(courseInfo.scorecard[0]?.tees?.teeBox1?.color && {
                    'teeBox1': {
                        'color': courseInfo.scorecard[0]?.tees?.teeBox1?.color || null,
                        'yards': null
                    }
                }),
                ...(courseInfo.scorecard[0]?.tees?.teeBox2?.color && {
                    'teeBox2': {
                        'color': courseInfo.scorecard[0]?.tees?.teeBox2?.color || null,
                        'yards': null
                    }
                }),
                ...(courseInfo.scorecard[0]?.tees?.teeBox3?.color && {
                    'teeBox3': {
                        'color': courseInfo.scorecard[0]?.tees?.teeBox3?.color || null,
                        'yards': null
                    }
                }),
            }
        }

        if (value === '18' && scorecardData.length === 9) {
            scorecardData.push(holeOutline, holeOutline, holeOutline, holeOutline, holeOutline, holeOutline, holeOutline, holeOutline, holeOutline)
        } else if (value === '9' && scorecardData.length === 18) {
            scorecardData.splice(9, 9)
        }

        setCourseInfo({ ...courseInfo, scorecard: scorecardData, holes: value });
    };

    const holeRows = (action) => {
        if (numOfRows.length <= 2 && action === 'add') {
            setNumOfRows((prevRows) => ([...prevRows, prevRows.length + 1]))
        }
        if (numOfRows.length > 1 && action === 'remove') {
            setNumOfRows((prevRows) => (prevRows.slice(0, -1)));
        }
    }

    let scorecardData = [...courseInfo.scorecard]
    let colorData = [...color]
    const handleScorecard = (e, hole, tee) => {
        const { name, value } = e.target
        if ((name === 'color' || name === 'yards')) {
            if (hole === 0) {
                colorData[tee] = value
                scorecardData.map((hole) => (
                    hole.tees = {
                        ...hole.tees,
                        ['teeBox' + tee]: {
                            ...hole.tees?.['teeBox' + tee],
                            'color': colorData[tee]
                        }
                    }
                ))
            }

            if (hole !== 0) {
                scorecardData[hole - 1] = {
                    ...scorecardData[hole - 1],
                    tees: {
                        ...scorecardData[hole - 1]?.tees,
                        ['teeBox' + tee]: {
                            ...scorecardData[hole - 1]?.tees?.['teeBox' + tee],
                            'color': colorData[tee] || '',
                            'yards': Number(value),
                        }
                    }
                }
            }
        }

        if (name === 'Par') {
            scorecardData[hole - 1] = {
                ...scorecardData[hole - 1],
                'Par': Number(value),
            }
        }

        if (name === 'Handicap') {
            scorecardData[hole - 1] = {
                ...scorecardData[hole - 1],
                'Handicap': Number(value),
                'Hole': Number(hole)
            }
        }

        setCourseErrors({
            ...courseErrors,
            errors: { ...courseErrors?.errors, scorecard: '' }
        })

        console.log("courseInfo", courseInfo)

        setColor(colorData)
        setCourseInfo({ ...courseInfo, scorecard: scorecardData })
    }

    return (
        <>
            <Grid className={classes.formHeader} item xs={12}>
                <Typography variant='h6'>
                    Scorecard
                </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <Typography align='center'>Holes</Typography>
                <RadioGroup
                    className={classes.scorecardRadio}
                    name="holeLength"
                    value={courseInfo.holes}
                    onChange={nineOrEighteen}
                    row
                >
                    <FormControlLabel
                        key="9"
                        value="9"
                        control={<Radio size="small" className={classes.iconHandler} />}
                        label="9"
                    />
                    <FormControlLabel
                        key="18"
                        value="18"
                        control={<Radio size="small" className={classes.iconHandler} />}
                        label="18"
                    />
                </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
                <Typography align='center'>
                    Tees
                </Typography>
                <div align='center'>
                    <IconButton aria-label="Add tee box" onClick={() => holeRows('remove')} >
                        <RemoveCircleIcon className={classes.iconHandler} />
                    </IconButton>
                    {numOfRows.length}
                    <IconButton aria-label="Add tee box" onClick={() => holeRows('add')} >
                        <AddCircleIcon className={classes.iconHandler} />
                    </IconButton>
                </div>
            </Grid>
            <TableContainer style={{ border: !!courseErrors.errors.scorecard && '2px solid red' }}>
                <Table className={classes.scorecardTableContainer} aria-label="scorecard">
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.scorecardHolesCell}>Holes</TableCell>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((x, i) => (
                                <TableCell className={classes.scorecardHolesCell} key={i}>
                                    {x}
                                </TableCell>
                            ))}
                        </TableRow>
                        {numOfRows.map((tee, teeIndex) => (
                            <TableRow key={teeIndex}>
                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((hole, holeIndex) => (
                                    <TableCell key={holeIndex}>
                                        <TextField
                                            placeholder={hole === 0 ? `Tee ${tee}` : null}
                                            inputProps={{ maxLength: hole !== 0 ? 3 : 7 }}
                                            id={`yardInput${holeIndex}`}
                                            name={hole === 0 ? 'color' : 'yards'}
                                            fullWidth
                                            variant="outlined"
                                            autoComplete='nope'
                                            value={hole === 0 ? courseInfo.scorecard[hole]?.tees['teeBox' + tee]?.color || '' : courseInfo.scorecard[hole - 1]?.tees['teeBox' + tee]?.yards || ''}
                                            error={!!courseErrors.errors.scorecard}
                                            onChange={(e) => handleScorecard(e, hole, tee)}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell className={classes.scorecardParCell}>Par</TableCell>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((hole, holeIndex) => (
                                <TableCell key={holeIndex}>
                                    <TextField
                                        inputProps={{ maxLength: 1 }}
                                        id={`parInput${hole}`}
                                        name="Par"
                                        fullWidth
                                        variant="outlined"
                                        autoComplete='nope'
                                        value={courseInfo.scorecard[holeIndex]?.Par || ''}
                                        error={!!courseErrors.errors.scorecard}
                                        onChange={(e) => handleScorecard(e, hole, holeIndex)}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.scorecardHandicapCell}>Handicap</TableCell>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((hole, holeIndex) => (
                                <TableCell key={holeIndex}>
                                    <TextField
                                        inputProps={{ maxLength: 2 }}
                                        id={`handicapInput${hole}`}
                                        name="Handicap"
                                        fullWidth
                                        variant="outlined"
                                        autoComplete='nope'
                                        value={courseInfo.scorecard[holeIndex]?.Handicap || ''}
                                        error={!!courseErrors.errors.scorecard}
                                        onChange={(e) => handleScorecard(e, hole, holeIndex)}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
                {courseInfo.holes === '18' &&
                    <Table className={classes.scorecardTableContainer} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.scorecardHolesCell}>Holes</TableCell>
                                {[10, 11, 12, 13, 14, 15, 16, 17, 18].map((x, i) => (
                                    <TableCell className={classes.scorecardHolesCell} key={i}>
                                        {x}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {numOfRows.map((tee, teeIndex) => (
                                <TableRow key={teeIndex}>
                                    {[0, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((hole, holeIndex) => (
                                        <TableCell key={holeIndex}>
                                            <TextField
                                                placeholder={hole === 0 ? `Tee ${tee}` : null}
                                                inputProps={{ maxLength: hole !== 0 ? 3 : 7 }}
                                                id={`yardInput${hole}`}
                                                name={hole === 0 ? 'color' : 'yards'}
                                                fullWidth
                                                variant="outlined"
                                                autoComplete='nope'
                                                value={hole === 0 ? courseInfo.scorecard[hole]?.tees['teeBox' + tee]?.color || '' : courseInfo.scorecard[hole - 1]?.tees['teeBox' + tee]?.yards || ''}
                                                error={!!courseErrors.errors.scorecard}
                                                onChange={(e) => handleScorecard(e, hole, tee)}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell className={classes.scorecardParCell}>Par</TableCell>
                                {[10, 11, 12, 13, 14, 15, 16, 17, 18].map((hole, holeIndex) => (
                                    <TableCell key={holeIndex}>
                                        <TextField
                                            inputProps={{ maxLength: 1 }}
                                            id={`parInput${hole}`}
                                            name="Par"
                                            fullWidth
                                            variant="outlined"
                                            autoComplete='nope'
                                            value={courseInfo.scorecard[hole - 1]?.Par || ''}
                                            error={!!courseErrors.errors.scorecard}
                                            onChange={(e) => handleScorecard(e, hole, holeIndex)}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.scorecardHandicapCell}>Handicap</TableCell>
                                {[10, 11, 12, 13, 14, 15, 16, 17, 18].map((hole, holeIndex) => (
                                    <TableCell key={holeIndex}>
                                        <TextField
                                            inputProps={{ maxLength: 2 }}
                                            id={`handicapInput${hole}`}
                                            name="Handicap"
                                            fullWidth
                                            variant="outlined"
                                            autoComplete='nope'
                                            value={courseInfo.scorecard[hole - 1]?.Handicap || ''}
                                            error={!!courseErrors.errors.scorecard}
                                            onChange={(e) => handleScorecard(e, hole, holeIndex)}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>}
            </TableContainer>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FormHelperText error={!!courseErrors.errors.scorecard}>{courseErrors.errors.scorecard || ' '}</FormHelperText>
            </Grid>
        </>
    )
}

export default Scorecard