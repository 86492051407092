export const FETCH_COURSE_BY_NAME = "FETCH_COURSE_BY_NAME";
export const FETCH_COURSE_BY_ID = "FETCH_COURSE_BY_ID";
export const LIKE_COURSE = "LIKE_COURSE";
export const FETCH_MOST_LIKED_COURSES = "FETCH_MOST_LIKED_COURSES";
export const ADD_COURSE = "ADD_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATED_COURSES = "UPDATED_COURSES";
export const RESET_COURSE = "RESET_COURSE";
export const RESET_COURSES = "RESET_COURSES";

export const ADD_COURSE_INFO = "ADD_COURSE_INFO";
export const ADD_TEEBOXES = "ADD_TEEBOXES";
export const ADD_SCORECARD = "ADD_SCORECARD";

export const CONTACT_ME = "CONTACT_ME";

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const LIKE = "LIKE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const FETCH_POST = "FETCH_POST;";
export const COMMENT = "COMMENT;";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
